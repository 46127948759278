import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, Slider, Switch, TextField, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { API } from '../../lib/api';
import dayjs from 'dayjs';
import { useAuth } from '../../contexts/AuthContext';
import ContactsIcon from '@mui/icons-material/Contacts';
import ContactsConfig from "../../lib/config/contact";
import RecordModal from '../RecordModal/RecordModal';
import { FormProvider } from '../../contexts/CreateRecordFormContext';
// import { jwtDecode } from 'jwt-decode';

// const steps = [
//     '01 - Lead', '02 - Identified', '03 - Committee', '04 - Internal Revision', '05 - Committee Approval', '06 - Quote Sent', '07 - External Revision', '08 - Won'
// ];

const Overview = ({ project, setFeedback, setLogs }) => {
        const [openCreateModal, setOpenCreateModal] = React.useState(false);
    const [loading, setLoading] = React.useState(true)
    const [quoteNumber, setQuote] = React.useState("")
    const [quoteRevision, setRevision] = React.useState("")
    const [status, setStatus] = React.useState("")
    const [projectNumber, setProjectNumber] = React.useState("")
    const [competitors, setCompetitors] = React.useState("")
    const [name, setName] = React.useState("")
    const [salesStage, setStage] = React.useState("")
    const [account, setAccount] = React.useState("")
    const [industry, setIndustry] = React.useState("")
    const [marketServed, setMarketServed] = React.useState("")
    const [closeDate, setCloseDate] = React.useState("")
    const [opportunitySource, setOpportunitySource] = React.useState("")
    const [assumptions, setAssumptions] = React.useState("")
    const [winLoseReason, setWinLoseReason] = React.useState("")
    const [primaryContact, setPrimaryContact] = React.useState("")
    const [owner, setOwner] = React.useState("")
    const [projectManager, setProjectManager] = React.useState("")
    const [projectEngineer, setProjectEngineer] = React.useState("")
    const [controlsEngineer, setControlsEngineer] = React.useState("")
    const [winProbability, setWinProbability] = React.useState(0)
    const [sparePartsListSentToCustomer, setSparePartsSent] = React.useState(false)
    const [sparePartsSentDate, setSparePartsDate] = React.useState("")
    const [comments, setComments] = React.useState("")
    const [customerContacts,setCustomerContacts] = React.useState([])

    const [winColor, setWinColor] = React.useState("primary")
    const [customers,setCustomers] = React.useState([])
    const [users,setUsers] = React.useState([])

    const { user } = useAuth()

    const handleOpenCreateModal = () => setOpenCreateModal(true);
    const handleCloseCreateModal = () => setOpenCreateModal(false);

    const handleSliderChange = (e) => {
        const value = e.target.value
        setWinProbability(value)
        if (value <= 35) {
            setWinColor("error")
        } else if (value > 35 && value <= 55 ) {
            setWinColor("warning")
        } else if (value > 55 && value <= 79) {
            setWinColor("primary")
        } else {
            setWinColor("success")
        }
    }

    const handleChange = (e) => {
        setStatus(e.target.value)
    }

    const handleSubmit = async () => {

        const tempData = { 
            ...(quoteNumber && { quoteNumber }), 
            ...(quoteRevision &&  {quoteRevision }),
            ...(status && { status }),
            ...(projectNumber && {projectNumber}),
            // competitors: competitors,
            ...(name && { name }),
            ...(salesStage && { salesStage }),
            // customer: account,

            ...(industry && { industry }),
            ...(marketServed && { marketServed }),
            ...(closeDate && { closeDate }),
            ...(opportunitySource && { opportunitySource }),
            ...(assumptions && { assumptions }),
            ...(winLoseReason && { winLoseReason }),
            ...(primaryContact && { primaryContact }),
            ...(owner && { owner }),
            ...(winProbability && { winProbability }),
            ...(sparePartsSentDate && { sparePartsSentDate }),
            ...(comments && { comments }),
            ...(sparePartsListSentToCustomer && { sparePartsListSentToCustomer }),
            ...(projectManager && { projectManager }),
            ...(projectEngineer && { projectEngineer }),
            ...(controlsEngineer && { controlsEngineer }),
            lastUpdatedBy: user.email,
            lastUpdatedDate: Date.now()
        }

        const response = await API.Opportunities.UpdateSingle(project._id, tempData)
        if (response.status === 200) {
            console.log(response)
            setFeedback({open: true, message: response.data.message, type: "success"})

            const fetchNewLogs = await API.OpportunityLogs.FetchByOppId(project._id)
            setLogs(fetchNewLogs)
        } else {
            setFeedback({open: true, message: `Failed to update Opportunity: ${project.name}`, type: "error"})
        }
    }

    const handleCreateSubmit = async (data) => {
        const cid = project?.customer?._id

        if (!cid) {
            setFeedback(prevData => ({ ...prevData, open: true, message: "Cannot Resolve Customer ID...", type: "error" }))
            return
        }
        const response = await API.Contacts.Create(cid, data)

        if (response.status === 200) {
            setCustomerContacts(prev => [...prev, {label: response.data.data.name, value: response.data.data._id}])
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "success" }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "error" }))
        }

        handleCloseCreateModal()
    }

    React.useEffect( () => {
        async function fetchCustomers() {
            const response = await API.Customers.FetchAll(localStorage.token)
            let tempCustomers = response.map(customer => {
                return { value: customer._id, label: customer.companyName}
            })
            setCustomers(tempCustomers)
        }
        async function fetchUsers() {
            const response = await API.Users.FetchAll(localStorage.token)
            let tempUsers = response.map(user => {
                return { value: user._id, label: `${user.firstName} ${user.lastName}`}                
            })
            setUsers(tempUsers)
        }
        async function fetchCustomerContacts() {
            const response = await API.Customers.FetchById(project?.customer?._id, localStorage.token)     
            if (response) {
                let tempContacts = response.contacts.map(contact => {
                    return { value: contact._id, label: contact.name}                
                })
                setCustomerContacts(tempContacts)    
            }     
        }
        project && fetchCustomerContacts()
        fetchUsers()
        fetchCustomers()
        if (project && Object.keys(project).length > 0) {
    
            project?.status && setStatus(project.status)
            project?.quoteNumber && setQuote(project.quoteNumber)
            project?.quoteRevision && setRevision(project.quoteRevision)
            project?.projectNumber && setProjectNumber(project.projectNumber)
            project?.competitors && setCompetitors(project.competitors)
            project?.name && setName(project.name)
            project?.salesStage && setStage(project.salesStage)
            project?.customer?._id && setAccount(project.customer._id)
            project?.industry && setIndustry(project.industry)
            project?.marketServed && setMarketServed(project.marketServed)
            project?.closeDate && setCloseDate(project.closeDate)
            project?.opportunitySource && setOpportunitySource(project.opportunitySource)
            project?.assumptions && setAssumptions(project.assumptions)
            project?.winLoseReason && setWinLoseReason(project.winLoseReason)
            project?.owner?._id && setOwner(project.owner._id)
            project?.projectManager && setProjectManager(project.projectManager)
            project?.projectEngineer && setProjectEngineer(project.projectEngineer)
            project?.controlsEngineer && setControlsEngineer(project.controlsEngineer)
            project?.primaryContact && setPrimaryContact(project.primaryContact)
            project?.winProbability && setWinProbability(project.winProbability)
            project?.sparePartsListSentToCustomer && setSparePartsSent(project.sparePartsListSentToCustomer)
            project?.sparePartsSentDate && setSparePartsDate(project.sparePartsSentDate)
            project?.comments && setComments(project.comments)

            if (project?.winProbability <= 35) {
                setWinColor("error")
            } else if (project?.winProbability > 35 && project?.winProbability <= 55 ) {
                setWinColor("warning")
            } else if (project?.winProbability > 55 && project?.winProbability <= 79) {
                setWinColor("primary")
            } else {
                setWinColor("success")
            }

            setLoading(false)
        }
    },[project])

  return (
    loading ? null : (
        <FormProvider>
            <RecordModal open={openCreateModal} handleClose={handleCloseCreateModal} fields={ContactsConfig.createFields} title="Create New Contact" onSubmit={handleCreateSubmit} />
            <div className="col-xl-12 col-md-12">
        <div className='card'>
            <div className="card-header d-flex align-items-center justify-content-between">
                <h5>Status & Functions - <a href='#'>{project && project.name}</a></h5>
            </div>
            <div className="card-body">
                <Box sx={{ width: '100%', marginTop: "1em", marginBottom: "4em" }}>
                    <Stepper activeStep={1} alternativeLabel>
                        {[salesStage].map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                </Box>
                <h6 style={{margin: "1em"}}>Summary</h6>
                <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap", marginBottom: "2em"}}>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <TextField
                                label="Quote #"
                                variant="outlined"
                                value={quoteNumber && quoteNumber}
                                onChange={(e) => setQuote(e.target.value)}
                            />
                        </FormControl>
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        {/* <FormControl fullWidth>
                            <TextField
                                label="Status"
                                variant="outlined"
                                value={status && status}
                                onChange={(e) => setStatus(e.target.value)}
                            />
                        </FormControl> */}
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status && status} // would be the curr project.status
                                    label="Status"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"OPEN"}>OPEN</MenuItem>
                                    <MenuItem value={"QUOTE SENT"}>QUOTE SENT</MenuItem>
                                    <MenuItem value={"LOST"}>LOST</MenuItem>
                                    <MenuItem value={"WON"}>WON</MenuItem>
                                    {/* <MenuItem value={"Completed"}>Complete</MenuItem> */}
                                </Select>
                            </FormControl>
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <TextField
                                value={quoteRevision && quoteRevision}
                                onChange={(e) => setRevision(e.target.value)}
                                label="Revision"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <InputLabel>Win Probability</InputLabel>
                        <Slider
                            onChange={(e) => handleSliderChange(e)}
                            valueLabelDisplay="auto"
                            color={winColor}
                            value={winProbability}
                        />
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <TextField
                                value={projectNumber && projectNumber}
                                onChange={(e) => setProjectNumber(e.target.value)}
                                label="Project #"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    
                    {/* <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Competitors</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={competitors && competitors}
                                label="Competitors"
                                onChange={(e) => setCompetitors(e.target.value)}
                            >
                                <MenuItem value={"Competitor A"}>Competitor A</MenuItem>
                                <MenuItem value={"Competitor B"}>Competitor B</MenuItem>
                                <MenuItem value={"Competitor C"}>Competitor C</MenuItem>
                            </Select>
                        </FormControl>
                    </div> */}
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <TextField
                                value={name && name}
                                onChange={(e) => setName(e.target.value)}
                                label="Name"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sales Stage</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={salesStage && salesStage} // would be the curr project.status
                                label="Sales Stage"
                                onChange={(e) => setStage(e.target.value)}
                            >
                                
                                <MenuItem value={"01 - Lead"}>01 - Lead</MenuItem>
                                <MenuItem value={"02 - Identified"}>02 - Identified</MenuItem>
                                <MenuItem value={"03 - Committee"}>03 - Committee</MenuItem>
                                <MenuItem value={"04 - Internal Revision"}>04 - Internal Revision</MenuItem>
                                <MenuItem value={"05 - Committee Approval"}>05 - Committee Approval</MenuItem>
                                <MenuItem value={"06 - Quote Sent"}>06 - Quote Sent</MenuItem>
                                <MenuItem value={"07 - External Revision"}>07 - External Revision</MenuItem>
                                <MenuItem value={"08 - Won"}>08 - Won</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={project?.customer && project?.customer.organizationName}
                                label="Account"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    {/* {customers.length && (
                        <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Account</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={account && account}
                                    label="Account"
                                    onChange={(e) => setAccount(e.target.value)}
                                >
                                    {customers.length && customers.map( customer => (
                                        <MenuItem key={customer?.value} value={customer?.value}>{customer.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )} */}
                    
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Industry</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={industry && industry} // would be the curr project.status
                                label="Industry"
                                onChange={(e) => setIndustry(e.target.value)}
                            >
                                
                                <MenuItem value={"Blow Molding"}>Blow Molding</MenuItem>
                                <MenuItem value={"Compounding"}>Compounding</MenuItem>
                                <MenuItem value={"Extrusion - Blown Film"}>Extrusion - Blown Film</MenuItem>
                                <MenuItem value={"Extrusion - Cast Film"}>Extrusion - Cast Film</MenuItem>
                                <MenuItem value={"Extrusion - Coating"}>Extrusion - Coating</MenuItem>
                                <MenuItem value={"Extrusion - Fiber"}>Extrusion - Fiber</MenuItem>
                                <MenuItem value={"Extrusion - Profile"}>Extrusion - Profile</MenuItem>
                                <MenuItem value={"Extrusion - Sheet"}>Extrusion - Sheet</MenuItem>
                                <MenuItem value={"Extrusion - Wire & Cable"}>Extrusion - Wire & Cable</MenuItem>
                                <MenuItem value={"Injection Molding"}>Injection Molding</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Market Served</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={marketServed && marketServed} // would be the curr project.status
                                label="Market Served"
                                onChange={(e) => setMarketServed(e.target.value)}
                            >
                                <MenuItem value={"Automotive"}>Automotive</MenuItem>
                                <MenuItem value={"Compounding"}>Compounding</MenuItem>
                                <MenuItem value={"Flooring - Carpet"}>Flooring - Carpet</MenuItem>
                                <MenuItem value={"Flooring - LVT"}>Flooring - LVT</MenuItem>
                                <MenuItem value={"Food Packaging"}>Food Packaging</MenuItem>
                                <MenuItem value={"Medical Packaging"}>Medical Packaging</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                                <MenuItem value={"Packaging - Shrink/Protective"}>Packaging - Shrink/Protective</MenuItem>
                                <MenuItem value={"Pipe"}>Pipe</MenuItem>
                                <MenuItem value={"Roofing"}>Roofing</MenuItem>
                                <MenuItem value={"Textiles"}>Textiles</MenuItem>
                                <MenuItem value={"Wire/Cable"}>Wire/Cable</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Close Date"
                                    value={closeDate ? dayjs(closeDate) : null} // Ensure valid dayjs object
                                    onChange={(date) => setCloseDate(date)}
                                    fullWidth
                                    format="MM/DD/YYYY" // Updated prop, `inputFormat` is deprecated in MUI v5
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>        
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Opportunity Source</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={opportunitySource && opportunitySource} // would be the curr project.status
                                label="Opportunity Source"
                                onChange={(e) => setOpportunitySource(e.target.value)}
                            >  
                                <MenuItem value={"Advertising"}>Advertising</MenuItem>
                                <MenuItem value={"Cold Calls"}>Cold Calls</MenuItem>
                                <MenuItem value={"Email Blast"}>Email Blast</MenuItem>
                                <MenuItem value={"Existing Customer"}>Existing Customer</MenuItem>
                                <MenuItem value={"Reference"}>Reference</MenuItem>
                                <MenuItem value={"Trade Shows"}>Trade Shows</MenuItem>
                                <MenuItem value={"Website"}>Website</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Primary Contact</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={primaryContact && primaryContact}
                                label="Primary Contact"
                                onChange={(e) => setPrimaryContact(e.target.value)}
                            >
                            {customerContacts && customerContacts.length > 0 ? (
                                customerContacts.map(contact => (
                                    <MenuItem key={contact.value} value={contact.value}>{contact.label}</MenuItem>
                                ))
                            ) : null}
                            </Select>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Tooltip title={"Need To Add A New Contact?"}>     
                                    <IconButton onClick={handleOpenCreateModal}>
                                        <ContactsIcon color={"secondary"} />
                                    </IconButton>
                                </Tooltip>
                            </div>
    
                        </FormControl>
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Assumptions?</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={assumptions && assumptions} // would be the curr project.status
                                label="Primary Contact"
                                onChange={(e) => setAssumptions(e.target.value)}
                            >
                                <MenuItem value={"Made_OnQuote"}>Made / On Quote</MenuItem>
                                <MenuItem value={"None"}>None</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {users.length && (
                        <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Owner</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={owner && owner}
                                    label="Owner"
                                    onChange={(e) => setOwner(e.target.value)}
                                >
                                {users.length && users.map(user => (
                                    <MenuItem key={user.value} value={user.value}>{user.label}</MenuItem>
                                ))}   
                                </Select>
                            </FormControl>
                        </div>
                    )}
                
                </div>
                <h6 style={{margin: "1em"}}>Post Sales</h6>
                
                <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap", marginTop: "2em"}}>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Project Manager</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={projectManager && projectManager}
                                    label="Project Manager"
                                    onChange={(e) => setProjectManager(e.target.value)}
                                >
                                {[{ value: "Joe Gardon", label: "Joe Gardon"},{ value: "Gene Anderla", label: "Gene Anderla"}].map(user => (
                                    <MenuItem key={user.value} value={user.value}>{user.label}</MenuItem>
                                ))}   
                                </Select>
                        </FormControl>
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <TextField
                                value={projectEngineer && projectEngineer}
                                label="Project Engineer"
                                variant="outlined"
                                onChange={(e) => setProjectEngineer(e.target.value)}
                            />
                        </FormControl>
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <TextField
                                value={controlsEngineer && controlsEngineer}
                                label="Controls Engineer"
                                variant="outlined"
                                onChange={(e) => setControlsEngineer(e.target.value)}
                            />
                        </FormControl>
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Win/Lose Reason</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={winLoseReason && winLoseReason} // would be the curr project.status
                                label="Win/Lose Reason"
                                onChange={(e) => setWinLoseReason(e.target.value)}
                            >
                                <MenuItem value={"Customer not ready"}>Customer not ready</MenuItem>
                                <MenuItem value={"Good lead"}>Good lead</MenuItem>
                                <MenuItem value={"Install base"}>Install base</MenuItem>
                                <MenuItem value={"Lost to competition"}>Lost to competition</MenuItem>
                                <MenuItem value={"Lost to internal development"}>Lost to internal development</MenuItem>
                                <MenuItem value={"Lost to no decision"}>Lost to no decision</MenuItem>
                                <MenuItem value={"No bandwidth"}>No bandwidth</MenuItem>
                                <MenuItem value={"No budget"}>No budget</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                                <MenuItem value={"Price"}>Price</MenuItem>
                                <MenuItem value={"Product"}>Product</MenuItem>
                                <MenuItem value={"Relationship"}>Relationship</MenuItem>
                                <MenuItem value={"Track record"}>Track record</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                     
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <div style={{display:"flex", justifyContent: "space-evenly", alignItems: "center", margin: "1em"}}>
                        <p style={{color: "#6c757d", fontSize: "14px"}}>Spare Parts List Sent to Customer</p>
                        {/* <Tooltip title="Currently Unavailable"> */}
                        <Switch
                            checked={sparePartsListSentToCustomer}
                            onChange={(e) => setSparePartsSent(e.target.checked)}
                        />
                        </div>
                    </div>
                    <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Spare Parts Sent Date"
                                    value={sparePartsSentDate ? dayjs(sparePartsSentDate) : null} // Ensure valid dayjs object
                                    onChange={(date) => setSparePartsDate(date)}
                                    fullWidth
                                    inputFormat="MM/dd/yyyy"
                                    // renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>         
                    </div>
                    <div className='col-10 col-lg-6' style={{margin: "1em"}}>
                        <FormControl fullWidth>
                            <TextField
                                multiline
                                rows={4}
                                label="Comments"
                                variant="outlined"
                                value={comments && comments}
                                onChange={(e) => setComments(e.target.value)}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='row' style={{display: "flex", justifyContent: "center", marginTop: "1em"}}>
                    <div className='col-lg-4'>
                        <FormControl fullWidth>
                            <Button variant='outlined' onClick={handleSubmit}>Save</Button>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </FormProvider>
    )
  );
}

export default Overview