import { useEffect, useState } from "react"
// import DashboardConfig from "../../lib/config/dashboard"
import OppsByStatus from "./OppsByStatus"
import { useParams } from "react-router-dom"
import { API } from "../../lib/api"
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material"
import Feedback from "../../components/Feedback/Feedback"
import Contacts from "./Contacts"
import { AddCircle } from "@mui/icons-material"
import RecordModal from "../../components/RecordModal/RecordModal"
import { FormProvider } from "../../contexts/CreateRecordFormContext"
import { useAuth } from "../../contexts/AuthContext"
// import { jwtDecode } from "jwt-decode"

const Content = () => {
    const [customer, setCustomer] = useState({})
    const [opportunities, setOpportunities] = useState([])
    const headers = ["Name", "Assigned To", "Sales Stage"]
    const [state, setState] = useState(customer?.state || "")
    const [city, setCity] = useState(customer?.city || "")
    const [address, setAddress] = useState(customer?.address || "")
    const [status, setStatus] = useState(customer?.status || "")
    const [notes, setNotes] = useState(customer?.notes || "")
    const [customerType, setCustomerType] = useState(customer?.customerType || "")
    const [feedback, setFeedback] = useState({open: false, message: "", type: ""}) 
    const [contacts, setContacts] = useState([])
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [formattedContacts, setFormattedContacts] = useState([])

    // let user = jwtDecode(localStorage?.token);
    const { user } = useAuth()
    
    const { cid } = useParams();

    const handleOpenCreateModal = () => setOpenCreateModal(true);
    const handleCloseCreateModal = () => setOpenCreateModal(false);

    const handleSubmit = async () => {
        const tempData = { 
            ...(address && { address }),
            ...(status && { status }),
            ...(notes && { notes }),
            ...(state && {state }),
            ...(city && {city }),
            ...(customerType && { customerType }),
        }
        
        const response = await API.Customers.UpdateOne(cid, tempData)
        if (response.status === 200) {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "success" }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "error" }))
        }
    }

    const handleCreateSubmit = async (data) => { 
        let sourceType = data?.sourceType
        let industry = data?.industry
        let marketServed = data?.marketServed
        let primaryContact = data?.primaryContact?.value

        const requestReadyData = {
            owner: localStorage.id,
            customer: cid,
            name: data.name,
            createdBy: user.email,
            lastUpdatedBy: user.email,
            lastUpdatedDate: Date.now(),
            salesStage: "01 - Lead",
            ...(sourceType && { sourceType }),
            ...(industry && { industry }),
            ...(marketServed && { marketServed }),
            ...(primaryContact && { primaryContact }),
        }
        const response = await API.Opportunities.Create(requestReadyData)

        if (response.status === 200) {
            setOpportunities(prevOpportunities => [response.data.opportunity, ...prevOpportunities]);
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "success" }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "error" }))
        }
        handleCloseCreateModal()
    }

    const createFields = [
        { name: 'name', label: "Opportunity Name", type: "text", required: true },
        { name: 'primaryContact', label: "Primary Contact", type: "autocomplete", options: formattedContacts },
        { name: 'sourceType', label: "Source", type: "autocomplete", options: ['Advertising', 'Cold Calls', 'Email Blast', 'Existing Customer', 'Reference', 'Trade Shows', 'Website'], required: true },
        { name: 'industry', label: "Industry", type: "autocomplete", options: ['Blow Molding', 'Compounding', 'Extrusion - Blown Film', 'Extrusion - Cast Film', 'Extrusion - Coating', 'Extrusion - Fiber', 'Extrusion - Profile', 'Extrusion - Sheet', 'Extrusion - Wire & Cable', 'Injection Molding', 'Other'], required: true },
        { name: 'marketServed', label: "Market Served", type: "autocomplete", options: ['Automotive', 'Compounding', 'Flooring - Carpet', 'Flooring - LVT', 'Food Packaging', 'Medical Packaging', 'Other', 'Packaging - Shrink/Protective', 'Pipe', 'Roofing', 'Textiles', 'Wire/Cable'], required: true },
    ]

    useEffect(() => {
        async function getData() {
            const response = await API.Customers.FetchById(cid)
            const oppsResponse = await API.Opportunities.FetchByCustomer(cid)
            console.log(oppsResponse)
            setOpportunities(oppsResponse)

            setCustomer(response)

            setCity(response?.city)
            setState(response?.state)
            setAddress(response.address)
            setStatus(response.status)
            setNotes(response?.notes)
            setCustomerType(response?.customerType)
            setContacts(response?.contacts)

            let tempContacts = response?.contacts.map(contact => {
                return { value: contact._id, label: contact.name}
            })
            setFormattedContacts(tempContacts)
        }
        getData()
    },[])

    return (
        <>
            <Feedback props={feedback} setFeedback={setFeedback} />
            <FormProvider>
        <div className="row">
            {/* TEMP Open Opps */}
            <div className="col-xl-7 col-md-12 col-lg-10">
                <div className="card table-card" style={{maxHeight: "400px", minHeight: "400px", overflowY: "scroll"}}>

                    <div className="card-header" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <div className="col-8">
                        <h5>Open Opportunities - <a href="#">{customer && customer.organizationName}</a></h5>
                        </div>
                        <div className="col-2">
                        <Tooltip title={`Create New Opportunity for ${customer && customer.organizationName}`}>
                            <IconButton onClick={handleOpenCreateModal}>
                                <AddCircle color={"primary"} />
                            </IconButton>
                        </Tooltip>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover table-borderless mb-0">
                                <thead>
                                    <tr>
                                        {headers.map(header => (
                                            header === "Status" ? <th key={header} className="text-end">{header}</th> : <th key={header}>{header}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody id="projects-table">
                                    {opportunities.length ? (
                                        opportunities.map((quote) => (
                                        <tr key={quote._id}>
                                            <td><a href={`/opportunities/${quote._id}`}>{quote.name}</a></td>
                                            <td>{quote.owner.firstName} {quote.owner.lastName}</td>
                                            <td><label className={`badge  ${quote.salesStage === '01 - Lead' ? 'bg-light-primary' : quote.salesStage === '08 - Won' ? 'bg-light-success' : quote.salesStage === '05 - Committee Approval' ? 'bg-light-warning' : quote.salesStage === '04 - Internal Revision' ? 'bg-light-danger' : quote.salesStage === '06 - Quote Sent' ? 'bg-light-primary' : quote.salesStage === '02 - Identified' ? 'bg-light-dark' : quote.salesStage === '03 - Committee' ? 'bg-light-secondary' : quote.salesStage === "07 - External Revision" ? 'bg-light-danger' : 'bg-light-info'}`}>{quote.salesStage}</label></td>
                                        </tr>
                                        ))
                                        ) : (
                                        <tr>
                                            <td colSpan="6">No Opportunities Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> 

            <RecordModal open={openCreateModal} handleClose={handleCloseCreateModal} fields={createFields} title="Create New Opportunity" onSubmit={handleCreateSubmit} />
            {/* TEMP Open Opps by status */}
            <OppsByStatus opportunities={opportunities} organizationName={customer && customer.organizationName}/>
            
            {/* Contacts */}
            <Contacts cid={cid} contacts={contacts} setContacts={setContacts} setFeedback={setFeedback} />

            {/* TEMP Customer Overview */}
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h5 className="mb-0">Customer Overview - <a href="#">{customer && customer.organizationName}</a></h5>
                    </div>
                    <div className="card-body">

                        <h6 style={{margin: "1em"}}>General</h6>

                        <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap", marginBottom: "2em"}}>
                            <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="State"
                                        variant="outlined"
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}
                                    />
                                </FormControl>
                            </div>
                            <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                                <FormControl fullWidth>
                                    <TextField
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        label="City"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                                <FormControl fullWidth>
                                    <TextField
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        label="Address"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status} // would be the curr project.status
                                    label="Status"
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <MenuItem value={"New"}>New</MenuItem>
                                    <MenuItem value={"Active"}>Active</MenuItem>
                                    <MenuItem value={"Inactive"}>Inactive</MenuItem>
                                    <MenuItem value={"Pending"}>Pending</MenuItem>
                                    <MenuItem value={"Dormant"}>Dormant</MenuItem>
                                    <MenuItem value={"Churned"}>Churned</MenuItem>
                                </Select>
                            </FormControl>
                            </div>
                            <div className='col-10 col-lg-4' style={{margin: "0.5em"}}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Customer Type</InputLabel>
                                <Select
                                    
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={customerType} // would be the curr project.status
                                    label="Customer Type"
                                    onChange={(e) => setCustomerType(e.target.value)}
                                >
                                    <MenuItem value={"OEM"}>OEM</MenuItem>
                                    <MenuItem value={"End User"}>End User</MenuItem>
                                </Select>
                            </FormControl>
                            </div>
                        </div>
                        
                        <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap", marginBottom: "2em"}}>
                            <div className='col-10 col-lg-6' style={{margin: "1em"}}>
                                <FormControl fullWidth>
                                    <TextField
                                        multiline
                                        rows={4}
                                        label="Notes"
                                        variant="outlined"
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        
                        <div className='row' style={{display: "flex", justifyContent: "center", marginTop: "1em"}}>
                            <div className='col-lg-4'>
                                <FormControl fullWidth>
                                    <Button variant='outlined' onClick={handleSubmit}>Save</Button>
                                </FormControl>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        </FormProvider>
        </>
    )
}

export default Content