import axios from 'axios';


const production = true
const activeServer = production ? process.env.REACT_APP_SERVER_BASE : process.env.REACT_APP_TEMP_SERVER;

export const API = {

    Users: {

        Create: async (data) => {
            try {
                const response = await axios.post(`${activeServer}/users/new`, data, {
                    withCredentials: true // <-- Key for cookie-based auth
                });
                return response;
            } catch (error) {
                return { status: 400, data: error.response.data };
            }
        },

        Update: async (updates) => {
            try {
                const response = await axios.patch(`${activeServer}/users/multi-update`, { updates }, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return { status: 400, data: error.response?.data || { message: 'Update failed' } };
            }
        },

        UpdateOne: async (id, data) => {
            try {
                const response = await axios.patch(`${activeServer}/users/update/${id}`, data, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                return response;
            } catch (error) {
                return {
                    status: error.response?.status || 500,
                    data: error.response?.data || "Request failed",
                };
            }
        },

        Delete: async (ids) => {
            try {
                const response = await axios.delete(`${activeServer}/users/delete`, { 
                    data: { ids: ids },
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return { status: 400, data: error.response?.data || { message: 'Delete failed' } };
            }
        },

        FetchAll: async () => {
            try {
                const response = await axios.get(`${activeServer}/users`, {
                    withCredentials: true // Send cookie automatically
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                return [];
            }
        },

        FetchById: async (id) => {
            try {
                // Incomplete API Route
            } catch (error) {
                
            }
        }

    },

    Customers: {

        Create: async (id, data) => {
            try {
                const response = await axios.post(`${activeServer}/customers/new/${id}`, data, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return error?.response;
            }
        },

        Update: async (updates) => {
            try {
                console.log(updates);
                const response = await axios.patch(`${activeServer}/customers/multi-update`, { updates }, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Update failed" }
                };
            }
        },

        UpdateOne: async (cid, data) => {
            try {
                const response = await axios.patch(`${activeServer}/customers/updateone/${cid}`, data, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Update failed" }
                };
            }
        },

        Delete: async (ids) => {
            try {
                const response = await axios.delete(`${activeServer}/customers/delete`, {
                    data: { ids },
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Delete failed" }
                };
            }
        },

        FetchAll: async () => {
            try {
                const response = await axios.get(`${activeServer}/customers`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                console.log(error);
                return [];
            }
        },

        FetchById: async (id) => {
            try {
                const response = await axios.get(`${activeServer}/customers/sn/${id}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : {};
            } catch (error) {
                console.log(error);
                return {};
            }
        },

    },

    Opportunities: {

        Create: async (data) => {
            try {
                const response = await axios.post(`${activeServer}/opportunities/new`, data, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Create failed" }
                };
            }
        },

        Update: async (updates) => {
            try {
                const response = await axios.patch(`${activeServer}/opportunities/multi-update`, { updates }, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    data: error.response?.data || { message: "Update failed" },
                    status: 400
                };
            }
        },
      
        UpdateSingle: async (id, payload) => {
            try {
                const response = await axios.patch(`${activeServer}/opportunities/update/${id}`, payload, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                console.log(error);
                return {
                    data: error.response?.data || { message: "Update failed" },
                    status: 400
                };
            }
        },

        Delete: async (ids) => {
            try {
                const response = await axios.delete(`${activeServer}/opportunities/delete`, {
                    data: { ids },
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    data: error.response?.data || { message: "Delete failed" },
                    status: 400
                };
            }
        },

        FetchAll: async () => {
            try {
                const response = await axios.get(`${activeServer}/opportunities`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                return [];
            }
        },

        FetchArchived: async () => {
            try {
                const response = await axios.get(`${activeServer}/opportunities/archived`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                return [];
            }
        },

        FetchById: async (id) => {
            try {
                const response = await axios.get(`${activeServer}/opportunities/sn/${id}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                console.log(error);
                return [];
            }
        },

        FetchByCustomer: async (id) => {
            try {
                const response = await axios.get(`${activeServer}/opportunities/customer/${id}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        
        FetchByStatus: async () => {
            try {
                // Incomplete API Route
            } catch (error) {
                
            }
        },

        FetchByUser: async (id) => {
            try {
                const response = await axios.get(`${activeServer}/quotes/user/${id}`, {
                    withCredentials: true
                  });
                return response.status === 200 ? response.data : []
            } catch (error) {
                console.log(error)
                return []
            }
        }

    },

    Settings: {

        Update: async (id, payload) => {
            try {
                const response = await axios.patch(`${activeServer}/settings/update/${id}`, payload, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                console.log(error);
                return {
                    status: 400,
                    data: error.response?.data || { message: 'Settings update failed' }
                };
            }
        },

        FetchByUser: async (id) => {
            try {
                const response = await axios.get(`${activeServer}/settings/user/${id}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : {};
            } catch (error) {
                return {};
            }
        },

    },

    Lines: {
        Create: async (oid, data) => {
            try {
                const response = await axios.post(`${activeServer}/lines/new/${oid}`, data, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Create failed" }
                };
            }
        },

        FetchByOpportunity: async (oid) => {
            try {
                const response = await axios.get(`${activeServer}/lines/opp/${oid}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                console.log(error);
                return [];
            }
        },

        FetchById: async (id) => {
            try {
                const response = await axios.get(`${activeServer}/lines/sn/${id}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : {};
            } catch (error) {
                return {};
            }
        },

        UpdateMulti: async (oid, updates) => {
            try {
                const response = await axios.patch(`${activeServer}/lines/multi-update/${oid}`, { updates }, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    data: error.response?.data || { message: "Update failed" },
                    status: 400
                };
            }
        },

        UpdateSingle: async (id, oid, payload) => {
            try {
                const response = await axios.patch(`${activeServer}/lines/update/${id}?oid=${oid}`, payload, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                console.log(error);
                return {
                    data: error.response?.data || { message: "Update failed" },
                    status: 400
                };
            }
        },   

        Delete: async (ids) => {
            try {
                const response = await axios.delete(`${activeServer}/lines/delete`, {
                    data: { ids },
                    withCredentials: true
                });
                console.log(response);
                return response;
            } catch (error) {
                console.log(error);
                return {
                    data: error.response?.data || { message: "Delete failed" },
                    status: 400
                };
            }
        },
    },

    Contacts: {

        Create: async (cid, data) => {
            try {
                const response = await axios.post(`${activeServer}/contact/new/${cid}`, data, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Create failed" }
                };
            }
        },

        Delete: async (cid, ids) => {
            try {
                const response = await axios.delete(`${activeServer}/contact/delete/${cid}`, {
                    data: { ids },
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Delete failed" }
                };
            }
        },

        Update: async (updates) => {
            try {
                const response = await axios.patch(`${activeServer}/contact/multi-update`, { updates }, {
                    withCredentials: true
                });
                return response
            } catch (error) {
                return { status: 400, data: error.response.data}
            }
        },

    },

    Reporting: {
        
        FetchCustomerMaster: async () => {
            try {
                const response = await axios.get(`${activeServer}/reporting/customer-master`, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 401,
                    data: error?.response?.data || { message: "Unauthorized" }
                };
            }
        },

        FetchForecastFile: async () => {
            try {
                const response = await axios.get(`${activeServer}/reporting/forecast-file`, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 401,
                    data: error?.response?.data || { message: "Unauthorized" }
                };
            }
        },

        FetchOppsByUser: async () => {
            try {
                const response = await axios.get(`${activeServer}/reporting/opps-by-user`, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 401,
                    data: error?.response?.data || { message: "Unauthorized" }
                };
            }
        },

        RevLinesTSTM: async () => {
            try {
                const response = await axios.get(`${activeServer}/reporting/revlines-tstm`, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 401,
                    data: error?.response?.data || { message: "Unauthorized" }
                };
            }
        },

        ProjectsByJoe: async (req,res) => {
            try {
                const response = await axios.get(`${activeServer}/reporting/projects/joe`, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 401,
                    data: error?.response?.data || { message: "Unauthorized" }
                };
            }
        },

        ProjectsByGene: async (req,res) => {
            try {
                const response = await axios.get(`${activeServer}/reporting/projects/gene`, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 401,
                    data: error?.response?.data || { message: "Unauthorized" }
                };
            }
        }
    },

    OpportunityLogs: {
        FetchByOppId: async (id) => {
            try {
                const response = await axios.get(`${activeServer}/opportunity-logs/${id}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                console.log(error);
                return [];
            }
        },
    },

    Dashboard: {
        SendOppsToDash: async (id, data) => {
            try {
                const response = await axios.patch(`${activeServer}/dashboard-settings/add-opps/${id}`, { data }, {
                    withCredentials: true
                })
                return response 
            } catch (error) {
                return { status: 400, data: error.response.data}
            }
        },

        FetchByUser: async (uid) => {
            try {
                const response = await axios.get(`${activeServer}/dashboard-settings/${uid}`, {
                    withCredentials: true
                })
                return response.data[0]
            } catch (error) {
                return { status: 400, data: error.response.data}
            }
        },

        RemoveOpp: async (uid, id) => {
            try {
                const response = await axios.delete(`${activeServer}/dashboard-settings/remove-opp/${uid}`, {
                    data: { id },
                    withCredentials: true
                })
                return response 
            } catch (error) {
                return { status: 400 , data: "Failed To Remove Opportunity..."}
            }
        },

        SendCustomersToDash: async (id, data) => {
            try {
                const response = await axios.patch(`${activeServer}/dashboard-settings/add-customers/${id}`, { data }, {
                    withCredentials: true
                })
                return response 
            } catch (error) {
                return { status: 400, data: error.response.data}
            }
        },

        RemoveCustomer: async (uid, id) => {
            try {
                const response = await axios.delete(`${activeServer}/dashboard-settings/remove-customer/${uid}`, {
                    data: { id },
                    withCredentials: true
                })
                return response 
            } catch (error) {
                return { status: 400 , data: "Failed To Remove Customer..."}
            }
        },
    },

    Authentication: {

        Login: async (formData) => {
            try {
                const response = await axios.post(`${activeServer}/auth/login`,formData,
                    { withCredentials: true } // include cookie!
                );
                return response
            } catch (error) {
                return error   
            }
        },

        ValidateUser: async () => {
            try {
                const response = await axios.get(`${activeServer}/auth/check`, {
                    withCredentials: true
                });
                return response
            } catch (error) {
                return error
            }
        },

        Logout: async () => {
            try {
                const response = await axios.post(`${activeServer}/auth/logout`, {}, {
                    withCredentials: true
                });
                return response
            } catch (error) {
                return error
            }
        }
    }

}