import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import SingleQuote from './components/SingleQuote/SingleQuote'
import Construction from './pages/Construction/Construction'
import Reporting from './pages/Reporting/Reporting'
import Customers from './pages/Customers/Customers'
import Schedule from './pages/Schedule/Schedule'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import Users from './pages/Users/Users'
import Login from './pages/Login/Login';
import Settings from './pages/Settings/Settings'
import Opportunities from './pages/Opportunities/Opportunities';
import OpportunityLines from './pages/OpportunityLines/OpportunityLines';
import SingleCustomer from './pages/SingleCustomer/SingleCustomer';
import Contacts from './pages/Contacts/Contacts';
import EngineerWrapper from './components/EngineerWrapper/EngineerWrapper';
import ForecastDataGrid from './pages/Modules/ForecastDataGrid/ForecastDataGrid';
import RevLinesTSTM from './pages/Modules/RevLinesTSTM/RevLinesTSTM';
import OppsByUser from './pages/Modules/OppsByUser/OppsByUser';
import ProjectsByJoe from './pages/Modules/ProjectsByJoe/ProjectsByJoe';
import ProjectsByGene from './pages/Modules/ProjectsByGene/ProjectsByGene'

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Login/>} />
                <Route 
                    path='/dashboard' 
                    element={<ProtectedRoute child={<Dashboard/>} />}
                />
                <Route 
                    path='/egr-dashboard' 
                    element={<ProtectedRoute child={<EngineerWrapper/>} />}
                />
                <Route
                    path='/constr'
                    element={<ProtectedRoute child={<Construction />} />}
                />
                <Route
                    path="/opportunities"
                    element={<ProtectedRoute child={<Opportunities />} />}
                />
                <Route
                    path="/opportunities/:pid"
                    element={<ProtectedRoute child={<SingleQuote />} />}
                />
                <Route
                    path="/schedule"
                    element={<ProtectedRoute child={<Schedule />} />}
                />
                <Route
                    path="/settings"
                    element={<ProtectedRoute child={<Settings />}/>}
                />
                <Route
                    path="/reporting"
                    element={<ProtectedRoute child={<Reporting />} />}
                />
                <Route
                    path="/users"
                    element={<ProtectedRoute child={<Users />} />}
                />
                <Route
                    path="/customers"
                    element={<ProtectedRoute child={<Customers />} />}
                />
                <Route
                    path="/contacts"
                    element={<ProtectedRoute child={<Contacts />} />}
                />
                <Route
                    path="/lines/:lid"
                    element={<ProtectedRoute child={<OpportunityLines/>} />}
                />
                <Route
                    path="/customers/:cid"
                    element={<ProtectedRoute child={<SingleCustomer />} />}
                />

                {/* Modules */}
                <Route
                    path="/modules/forecast-data-grid"
                    element={<ProtectedRoute child={<ForecastDataGrid />} />}
                />
                <Route
                    path="/modules/revlines-tstm"
                    element={<ProtectedRoute child={<RevLinesTSTM />} />}
                />
                <Route
                    path="/modules/opps-by-owner"
                    element={<ProtectedRoute child={<OppsByUser />} />}
                />
                <Route
                    path="/modules/projects-by-joe"
                    element={<ProtectedRoute child={<ProjectsByJoe />} />}
                />
                <Route
                    path="/modules/projects-by-gene"
                    element={<ProtectedRoute child={<ProjectsByGene />} />}
                />

            </Routes>
        </Router>
    );
}

export default AppRouter